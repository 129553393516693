import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  // State variables
  const [appState, setAppState] = useState({
    user: {},
    alert: {
      type: '',
      message: '',
      showing: false,
    },
    windowSize: window.innerWidth,
    appLoading: true,
    loadingMessage: null,
  });

  // Functions to update state
  const updateUser = (user) => {
    setAppState((prevState) => ({ ...prevState, user }));
  };

  const updateAlert = (alert) => {
    setAppState((prevState) => ({ ...prevState, alert }));
  };

  const updateWindowSize = (windowSize) => {
    setAppState((prevState) => ({ ...prevState, windowSize }));
  };

  const updateAppLoading = (appLoading) => {
    setAppState((prevState) => ({ ...prevState, appLoading }));
  };

  const updateLoadingMessage = (loadingMessage) => {
    setAppState((prevState) => ({ ...prevState, loadingMessage }));
  };

  return (
    <AppContext.Provider value={{ appState, updateUser, updateAlert, updateWindowSize, updateAppLoading, updateLoadingMessage }}>
      {children}
    </AppContext.Provider>
  );
};
