import { useEffect, useState } from 'react';
import { Box, Button, Menu, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import TuneIcon from '@mui/icons-material/Tune';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';

import { darkTheme } from 'constants/styles';
import MultiInput from 'components/Form/MultiInput';
import MultiSelect from 'components/Form/MultiSelect';
import { DateRangePicker } from 'components/Form/DatePickers';
import { IntegerRangePicker } from 'components/Form/IntegerInput';

export const TYPES = {
  INTEGER_RANGE_PICKER: 'INTEGER_RANGE_PICKER',
  MULTI_INPUT: 'MULTI_INPUT',
  DATE_RANGE_PICKER: 'DATE_RANGE_PICKER',
  MULTI_SELECT: 'MULTI_SELECT',
};

const AddFiltersMenu = ({ searchParamsObj, updateSearchParams, filterConfig = [], resetObj = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [localSearchParams, setLocalSearchParams] = useState(searchParamsObj);
  const isDifferent = !isEqual(searchParamsObj, localSearchParams);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setLocalSearchParams(searchParamsObj);
  }, [searchParamsObj]);

  useEffect(() => {
    setLocalSearchParams(searchParamsObj);
  }, [searchParamsObj]);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetForm = () => {
    updateSearchParams(resetObj);
    handleClose();
  };

  const handleUpdateSearchParams = () => {
    updateSearchParams(localSearchParams);
    handleClose();
  };

  const handleChange = (searchParam, value) => {
    let copyOfValues = structuredClone(localSearchParams);
    if (!value || (Array.isArray(value) && !value.length > 0)) {
      delete copyOfValues[searchParam];
    } else {
      set(copyOfValues, searchParam, value);
    }
    setLocalSearchParams(copyOfValues);
  };

  const renderComponent = (config) => {
    if (config.type === TYPES.MULTI_INPUT) {
      const value = localSearchParams[config.attribute]?.split(',') || [];
      return (
        <div>
          <Typography sx={{ fontWeight: 700 }}>{config.label}</Typography>
          <MultiInput
            options={config.options || []}
            label={config.label}
            value={value}
            handleChange={(e, val) => handleChange(config.attribute, val.join(','))}
          />
        </div>
      );
    } else if (config.type === TYPES.MULTI_SELECT) {
      const value = localSearchParams[config.attribute]?.split(',') || [];
      return (
        <div>
          <Typography sx={{ fontWeight: 700 }}>{config.label}</Typography>
          <MultiSelect
            value={value}
            options={config.options}
            handleChange={(option, checked) => {
              if (checked && !value?.includes(option)) {
                handleChange(config.attribute, [...value, option].join(','));
              } else {
                handleChange(config.attribute, value?.filter((val) => val != option).join(','));
              }
            }}
          />
        </div>
      );
    } else if (config.type === TYPES.DATE_RANGE_PICKER) {
      const startingDate = localSearchParams[config.attributes[0]] || null;
      const endingDate = localSearchParams[config.attributes[1]] || null;

      return (
        <div>
          <Typography sx={{ fontWeight: 700 }}>{config.labels[2]}</Typography>
          <DateRangePicker
            startingLabel={config.labels[0]}
            endingLabel={config.labels[1]}
            startingDate={startingDate}
            endingDate={endingDate}
            handleStartingChange={(newDate) => handleChange(config.attributes[0], newDate)}
            handleEndingChange={(newDate) => handleChange(config.attributes[1], newDate)}
          />
        </div>
      );
    } else if (config.type === TYPES.INTEGER_RANGE_PICKER) {
      const startingValue = localSearchParams[config.attributes[0]] || null;
      const endingValue = localSearchParams[config.attributes[1]] || null;

      return (
        <div>
          <Typography sx={{ fontWeight: 700 }}>{config.labels[2]}</Typography>
          <IntegerRangePicker
            startingLabel={config.labels[0]}
            endingLabel={config.labels[1]}
            startingValue={startingValue}
            endingValue={endingValue}
            handleStartingChange={(val) => handleChange(config.attributes[0], val)}
            handleEndingChange={(val) => handleChange(config.attributes[1], val)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
      <Button startIcon={<TuneIcon />} onClick={handleClick}>
        Add Filters
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'hidden',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              width: '800px',
              borderRadius: '8px',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* Header Row */}
        <div
          style={{
            boxSizing: 'border-box',
            padding: '8px 10px',
            margin: '-10px 0px 0px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottom: `1px solid ${darkTheme.colors.border}`,
          }}
        >
          <Typography color="primary" variant="h6">
            Sort and Filter
          </Typography>
        </div>

        {/* Content Row */}
        <div style={{ padding: '10px' }}>
          <Grid sx={{ maxHeight: 'calc(100vh - 220px)', overflowY: 'auto' }} spacing={2} container>
            {filterConfig.map((config) => (
              <Grid xs={6} key={config.attribute ? config.attribute : config.attributes ? config.attributes[0] : `${Math.random()}`}>
                {renderComponent(config)}
              </Grid>
            ))}
          </Grid>
        </div>
        {/* Action Row */}
        <div
          style={{
            boxSizing: 'border-box',
            padding: '10px 10px 0px',
            marginTop: '10px',
            width: '100%',
            borderTop: `1px solid ${darkTheme.colors.border}`,
          }}
        >
          <Button onClick={resetForm} sx={{ width: '50%' }}>
            Reset
          </Button>
          <Button disabled={!isDifferent} onClick={handleUpdateSearchParams} sx={{ width: '50%' }} variant="contained">
            Apply
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default AddFiltersMenu;
