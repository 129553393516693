import Grid from '@mui/material/Unstable_Grid2/Grid2.js';

import { currencyFormatterNoDecimal } from 'constants/formatters';
import { MinMaxAvgChart } from 'components/Charts/BarChart';
import { BooleanPieChartCard } from 'components/Charts/BooleanPieChart';

const Statistics = ({ statistics }) => {
  return (
    <Grid container spacing={2} style={{ overflowY: 'auto', paddingTop: '10px', marginTop: '0px' }}>
      <Grid xs={4}>
        <BooleanPieChartCard
          title="UBI Complete"
          dataset={Object.entries(statistics?.ubi_complete || {}).map(([key, value]) => ({ name: key == 'true' ? 'Complete' : 'Incomplete', value }))}
        />
      </Grid>
      <Grid xs={4}>
        <BooleanPieChartCard
          title="New/Used"
          dataset={Object.entries(statistics?.condition || {}).map(([key, value]) => ({ name: key == 'new' ? 'New' : 'Used', value }))}
        />
      </Grid>
      <Grid xs={4}>
        <BooleanPieChartCard
          title="In Stock"
          dataset={Object.entries(statistics?.in_stock || {}).map(([key, value]) => ({ name: key == 'true' ? 'In-Stock' : 'Out of Stock', value }))}
        />
      </Grid>

      <Grid xs={6}>
        <MinMaxAvgChart title="Age" stats={statistics.age} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart title="Days to Sale" stats={statistics.days_to_sale} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart title="Days to UBI" stats={statistics.days_to_ubi} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart title="Length of UBI" stats={statistics.length_of_ubi} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart title="Mileage" stats={statistics.mileage} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Cost" stats={statistics.total_cost} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Price" stats={statistics.total_price} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="UBI Cost" stats={statistics.ubi_cost} />
      </Grid>
    </Grid>
  );
};

export default Statistics;
