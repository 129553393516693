import { VehiclesAPI } from 'store/api';

export const fetchVehicles = async (queryString) => {
  try {
    const response = await VehiclesAPI.getVehicles(queryString);

    return response;
  } catch (err) {
    console.log(`Error fetching vehicles: ${err}`);
  }
};

export const fetchVehicleDetail = async (identifier) => {
  try {
    const response = await VehiclesAPI.getVehicleDetail(identifier);

    return response;
  } catch (err) {
    console.log(`Error fetching vehicle detail for identifier ${identifier}: ${err}`);
  }
};

export default {
  fetchVehicles,
  fetchVehicleDetail,
};
