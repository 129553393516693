import { TextField, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';

export const IntegerInput = ({ value, label, handleChange }) => {
  return (
    <NumericFormat
      label={label}
      customInput={TextField}
      value={value}
      variant="filled"
      onValueChange={({ formattedValue, value, floatValue }) => {
        handleChange(floatValue);
      }}
      allowNegative={false}
      decimalScale={0}
      thousandSeparator=","
      size="small"
    />
  );
};

export const IntegerRangePicker = ({ startingLabel, startingValue, endingValue, endingLabel, handleStartingChange, handleEndingChange }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box' }}>
      <IntegerInput label={startingLabel} value={startingValue} handleChange={handleStartingChange} />
      <Typography sx={{ m: '0px 5px' }}>-</Typography>
      <IntegerInput label={endingLabel} value={endingValue} handleChange={handleEndingChange} />
    </div>
  );
};
