const ROUTE_MODEL = {
  DASHBOARD: {
    PATH: '/app/dashboard',
    RELATIVE_PATH: 'dashboard',
  },

  VEHICLES: {
    PATH: '/app/vehicles',
    RELATIVE_PATH: 'vehicles',

    VEHICLE_DETAIL: {
      PATH: (id) => `/app/vehicles/${id}`,
      RELATIVE_PATH: ':id',
    },
  },

  DEALS: {
    PATH: '/app/deals',
    RELATIVE_PATH: 'deals',

    DEAL_DETAIL: {
      PATH: (id) => `/app/deals/${id}`,
      RELATIVE_PATH: ':id',
    },
  },

  WORK_ORDERS: {
    PATH: '/app/work-orders',
    RELATIVE_PATH: 'work-orders',

    WORK_ORDER_DETAIL: {
      PATH: (id) => `/app/work-orders/${id}`,
      RELATIVE_PATH: ':id',
    },
  },

  LOGIN: {
    PATH: '/login',
  },

  ADMIN: {
    PATH: '/app/admin',
    RELATIVE_PATH: 'admin',
  },

  ACCOUNT: {
    PATH: '/app/my-account',
    RELATIVE_PATH: 'my-account',
  },
};

export default ROUTE_MODEL;
