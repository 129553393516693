import { Box, Typography } from '@mui/material';
import { BarChart as ReBarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Grid from '@mui/material/Unstable_Grid2/Grid2.js';

import { Card } from 'components/Layouts';
import { numberFormatter } from 'constants/formatters';
import { darkTheme } from 'constants/styles';

export function transformDataForChartWithMissingRanges(dataObject) {
  const parseRange = (range) => {
    if (range === '<0') {
      return { start: -Infinity, end: 0 }; // Special handling for "<0" range
    }
    let [start, end] = range.replace('+', '').split('_');
    start = parseFloat(start);
    if (end != 0 && !end) {
      end = Infinity;
    } else {
      end = parseFloat(end);
    }
    return { start, end };
  };

  const stringFormatNumber = (num) => {
    if (num === -Infinity) return '<0'; // Special formatting for -Infinity
    if (num >= 0) return `${num}`;
    else return `(${Math.abs(num)})`;
  };

  let dataArray = Object.keys(dataObject).map((key) => {
    const { start, end } = parseRange(key);
    return {
      x:
        end === Infinity
          ? `${stringFormatNumber(start)}+`
          : start === -Infinity
          ? stringFormatNumber(start)
          : `${stringFormatNumber(start)}-${stringFormatNumber(end)}`,
      y: dataObject[key],
      start,
      end,
    };
  });

  // Sort by range start considering -Infinity, then by range end if starts are equal
  dataArray.sort((a, b) => {
    if (a.start === -Infinity) return -1; // Ensure "<0" comes first
    if (b.start === -Infinity) return 1;
    return a.start - b.start || a.end - b.end;
  });

  let completeDataArray = [];
  dataArray.forEach((item, index) => {
    if (index > 0) {
      const previousEnd = dataArray[index - 1].end;
      const currentStart = item.start;

      // Fill gaps unless the current range starts where the previous one ended
      if (previousEnd !== currentStart && previousEnd !== -Infinity && currentStart !== -Infinity) {
        const missingRange = `${previousEnd.toFixed(0)}-${currentStart.toFixed(0)}`;
        completeDataArray.push({ x: missingRange, y: 0, start: previousEnd, end: currentStart });
      }
    }
    completeDataArray.push(item);
  });

  return completeDataArray;
}

export function keySortNumeric(dataObject) {
  const parseRange = (range) => {
    if (range === '<0') {
      return { start: -Infinity, end: 0 }; // Special handling for "<0" range
    }
    let [start, end] = range.replace('+', '').split('_');
    start = parseFloat(start);
    if (end != 0 && !end) {
      end = Infinity;
    } else {
      end = parseFloat(end);
    }
    return { start, end };
  };

  const stringFormatNumber = (num) => {
    if (num === -Infinity) return '<0'; // Special formatting for -Infinity
    if (num >= 0) return `${num}`;
    else return `(${Math.abs(num)})`;
  };

  let dataArray = Object.keys(dataObject).map((key) => {
    const { start, end } = parseRange(key);
    return {
      x:
        end === Infinity
          ? `${stringFormatNumber(start)}+`
          : start === -Infinity
          ? stringFormatNumber(start)
          : `${stringFormatNumber(start)}-${stringFormatNumber(end)}`,
      y: dataObject[key],
      start,
      end,
    };
  });

  // Sort by range start considering -Infinity, then by range end if starts are equal
  dataArray.sort((a, b) => {
    if (a.start === -Infinity) return -1; // Ensure "<0" comes first
    if (b.start === -Infinity) return 1;
    return a.start - b.start || a.end - b.end;
  });

  return dataArray;
}

export function keySortData(dataObject) {
  let dataArray = Object.keys(dataObject).map((key) => {
    return { x: key, y: dataObject[key] };
  });

  // Sort the array by the keys
  dataArray.sort((a, b) => parseInt(a.x) - parseInt(b.x));

  return dataArray;
}

export function valueSortData(dataObject) {
  let dataArray = Object.keys(dataObject).map((key) => {
    return { x: key, y: dataObject[key] };
  });

  // Sort the array by the values
  dataArray.sort((a, b) => b.y - a.y);

  return dataArray;
}

const CustomBarShape = (props) => {
  const { fill, stroke, x, y, width, height } = props;

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      stroke={stroke} // Border color
      fill={fill} // Fill color
      strokeWidth={2} // Border thickness
    />
  );
};

const BarChart = ({ height = 250, dataset }) => {
  return (
    <Box sx={{ width: '100%', boxSizing: 'border-box', paddingTop: '20px' }}>
      <ResponsiveContainer width="100%" height={height}>
        <ReBarChart data={dataset} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
          <XAxis dataKey="x" stroke={darkTheme.colors.text} tick={{ fontSize: '14px', fontFamily: darkTheme.typography.fontFamily }} />
          <YAxis stroke={darkTheme.colors.text} tick={{ fontSize: '14px', fontFamily: darkTheme.typography.fontFamily }} />
          <Tooltip
            labelStyle={{ fontFamily: darkTheme.typography.fontFamily, color: darkTheme.colors.textEmphasized, fontWeight: 700 }}
            itemStyle={{ fontFamily: darkTheme.typography.fontFamily, color: darkTheme.colors.text }}
            contentStyle={{
              backgroundColor: darkTheme.colors.surface,
              border: 'none',
              border: `1px solid ${darkTheme.colors.border}`,
              padding: '10px',
              borderRadius: darkTheme.borderRadius,
            }}
            formatter={(value, name) => [value, 'Frequency']}
          />
          <Bar dataKey="y" shape={<CustomBarShape fill={darkTheme.colors.primaryFaded} stroke={darkTheme.colors.primary} />} />
        </ReBarChart>
      </ResponsiveContainer>
    </Box>
  );
};

const DataTile = ({ title, value, color }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: darkTheme.colors.background,
        borderRadius: darkTheme.borderRadius,
        padding: '0px 0px 6px',
      }}
    >
      <Typography variant="h6" color={color || 'primary'}>
        {value}
      </Typography>
      <Typography variant="body2">{title}</Typography>
    </div>
  );
};

export const MinMaxAvgChart = ({
  title,
  stats,
  formatter = numberFormatter,
  transformType = 'default',
  minTitle = 'Min',
  avgTitle = 'Avg',
  maxTitle = 'Max',
  overrideFormatters = [],
}) => {
  const height = 200;
  const transforms = {
    ['default']: transformDataForChartWithMissingRanges,
    ['keySort']: keySortData,
    ['valueSort']: valueSortData,
    ['keySortNumberic']: keySortNumeric,
  };

  const dataset = stats?.count_by_bucket ? transforms[transformType](stats.count_by_bucket) : [];
  return (
    <Card>
      <Typography variant="h5">{title}</Typography>

      {dataset?.length > 0 ? (
        <BarChart height={height} dataset={dataset} />
      ) : (
        <div style={{ height: `${height + 20}px`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography>No Data</Typography>
        </div>
      )}

      <Grid container spacing={2} sx={{ mt: '12px' }}>
        <Grid xs={4}>
          <DataTile
            title={minTitle}
            value={overrideFormatters.includes('min') ? stats?.min : formatter.format(stats?.min < 0 ? 0 : stats?.min, 0)}
            color="primary"
          />
        </Grid>
        <Grid xs={4}>
          <DataTile
            title={avgTitle}
            value={overrideFormatters.includes('avg') ? stats?.avg : formatter.format(stats?.avg < 0 ? 0 : stats?.avg, 0)}
            color="primary"
          />
        </Grid>
        <Grid xs={4}>
          <DataTile
            title={maxTitle}
            value={overrideFormatters.includes('max') ? stats?.max : formatter.format(stats?.max < 0 ? 0 : stats?.max, 0)}
            color="primary"
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default BarChart;
