import { AuthAPI } from 'store/api';

export const login = async ({ email, password }) => {
  try {
    const { user } = await AuthAPI.login({ email, password });

    return user;
  } catch (err) {
    console.log(`Error logging in user`);
  }
};

export const logout = async () => {
  try {
    await AuthAPI.logout();

    return { message: 'Logout Successful' };
  } catch (err) {
    console.log(`Error logging out user`);
  }
};

export const getSession = async () => {
  try {
    const { user } = await AuthAPI.getSession();

    return { user };
  } catch (err) {
    console.log(`Error on Auth.getSession: ${err}`);
  }
};

export default {
  login,
  logout,
  getSession,
};
