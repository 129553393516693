import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const onSuccess = ({ data }) => {
  return data;
};

// TODO: Change this so it logs the error. Maybe make it trigger a user-facing toast that says something went wrong?
const onError = (response) => {
  return Promise.reject(response);
};

const createClient = () => {
  let baseURL = API_BASE_URL;
  return axios.create({ baseURL, withCredentials: true });
};

export const METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const request = ({ ...options }) => createClient()(options).then(onSuccess).catch(onError);
