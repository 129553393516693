import { VehicleDealsAPI } from 'store/api';

export const fetchVehicleDeals = async (queryString) => {
  try {
    const response = await VehicleDealsAPI.getDeals(queryString);

    return response;
  } catch (err) {
    console.log(`Error fetching vehicle deals: ${err}`);
  }
};

export const fetchDealDetail = async (identifier) => {
  try {
    const response = await VehicleDealsAPI.getDealDetail(identifier);

    return response;
  } catch (err) {
    console.log(`Error fetching vehicle deal detail for identifier ${identifier}: ${err}`);
  }
};

export default {
  fetchVehicleDeals,
  fetchDealDetail,
};
