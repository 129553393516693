import { useState, useEffect, useContext } from 'react';
import { TextField, Button, InputAdornment, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';

import { FlexRow, Card } from 'components/Layouts';
import { REGEX } from 'constants/regex';
import { AppContext } from 'AppContext';
import ROUTE_MODEL from 'constants/routeModel';
import { login } from 'store/actions/auth';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState({ err: false, tip: '' });
  const { appState, updateAppLoading, updateUser } = useContext(AppContext);
  const { user } = appState;
  const navigate = useNavigate();

  useEffect(() => {
    if (!!user?.id) {
      return navigate(ROUTE_MODEL.DASHBOARD.PATH, { replace: true });
    }
  }, [user]);

  const validateEmail = () => {
    if (REGEX.isEmail.test(email) || email === '') {
      setEmailError({
        err: false,
        tip: '',
      });
    } else {
      setEmailError({
        err: true,
        tip: 'Please enter a valid email address.',
      });
    }
  };

  const loginUser = async () => {
    validateEmail();

    if (email === '') {
      setEmailError({
        err: true,
        tip: 'Please enter a valid email address.',
      });
    }

    if (!emailError.err) {
      try {
        updateAppLoading(true);
        const user = await login({ email: email.toLowerCase(), password });
        if (user) {
          updateUser(user);
        } else {
          console.log('Incorrect password or email');
        }
      } catch (err) {
        console.log('error logging in');
        console.log(err);
      } finally {
        updateAppLoading(false);
      }
    }
  };

  return (
    <FlexRow justifyContent="center" alignItems="center" padding="20px" fullHeight>
      <Card>
        <Typography color="text.primary" variant="h4" gutterBottom>
          Login
        </Typography>

        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={emailError.err}
          helperText={emailError.err ? emailError.tip : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={validateEmail}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') loginUser();
          }}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button fullWidth variant="contained" style={{ marginTop: '20px' }} size="large" onClick={loginUser} disabled={!email || !password}>
          Login
        </Button>
      </Card>
    </FlexRow>
  );
};

export default Login;
