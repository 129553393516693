import { useState, useEffect } from 'react';
import { Tab, Tabs, CircularProgress, Typography } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FlexRow } from 'components/Layouts';
import { darkTheme } from 'constants/styles';
import AddFiltersMenu, { TYPES } from 'components/AddFiltersMenu';
import WorkOrdersDataGrid from './DataGrid';
import Statistics from './Statistics';
import { fetchWorkOrders } from 'store/actions/workOrders';

const filtersConfiguration = [
  { attribute: 'type', type: TYPES.MULTI_SELECT, label: 'Type', options: ['customer', 'internal'] },
  { attribute: 'request_by', type: TYPES.MULTI_SELECT, label: 'Request By', options: ['customer', 'sales'] },
  { attribute: 'work_order_id', type: TYPES.MULTI_INPUT, label: 'Work Order IDs' },
  { attribute: 'customer_id', type: TYPES.MULTI_INPUT, label: 'Customer IDs' },
  { attribute: 'vehicle_vin', type: TYPES.MULTI_INPUT, label: 'Vehicle VINs' },
  { attribute: 'vehicle_stock_number', type: TYPES.MULTI_INPUT, label: 'Vehicle Stock Numbers' },
  { attribute: 'vehicle_model', type: TYPES.MULTI_INPUT, label: 'Vehicle Models' },
  { attribute: 'vehicle_year', type: TYPES.MULTI_INPUT, label: 'Vehicle Years' },
  { attribute: 'service_writer_employee_code', type: TYPES.MULTI_INPUT, label: 'Service Writer Employee Codes' },
  { attribute: 'technician_employee_code', type: TYPES.MULTI_INPUT, label: 'Technician Employee Codes' },
  { attributes: ['min_arrival_date', 'max_arrival_date'], type: TYPES.DATE_RANGE_PICKER, labels: ['Min', 'Max', 'Arrival Date'] },
  {
    attributes: ['min_completed_date', 'max_completed_date'],
    type: TYPES.DATE_RANGE_PICKER,
    labels: ['Min', 'Max', 'Completed Date'],
  },
  {
    attributes: ['min_effective_labor_rate_amount', 'max_effective_labor_rate_amount'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Effective Labor Rate Amount'],
  },
  {
    attributes: ['min_pay_technician_hours', 'max_pay_technician_hours'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Pay Technician Hours'],
  },
  {
    attributes: ['min_actual_labor_hours', 'max_actual_labor_hours'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Actual Labor Hours'],
  },
  {
    attributes: ['min_billed_labor_hours', 'max_billed_labor_hours'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Billed Labor Hours'],
  },
  { attributes: ['min_labor_cost', 'max_labor_cost'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min', 'Max', 'Labor Cost'] },
  { attributes: ['min_efficiency', 'max_efficiency'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min', 'Max', 'Efficiency'] },
  {
    attributes: ['min_parts_discount_percentage', 'max_parts_discount_percentage'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Parts Discount Percentage'],
  },
  {
    attributes: ['min_parts_gross_profit', 'max_parts_gross_profit'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Parts Gross Profit'],
  },
  {
    attributes: ['min_parts_gross_margin', 'max_parts_gross_margin'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Parts Gross Margin'],
  },
  {
    attributes: ['min_labor_gross_profit', 'max_labor_gross_profit'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Labor Gross Profit'],
  },
  {
    attributes: ['min_labor_gross_margin', 'max_labor_gross_margin'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Labor Gross Margin'],
  },
  {
    attributes: ['min_total_gross_profit', 'max_total_gross_profit'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Total Gross Profit'],
  },
  {
    attributes: ['min_total_gross_margin', 'max_total_gross_margin'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min', 'Max', 'Total Gross Margin'],
  },
];

const WorkOrders = () => {
  const [workOrders, setWorkOrders] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [statistics, setStatistics] = useState({});
  const [tab, setTab] = useState('workOrders'); // workOrders || stats
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const searchParamsObj = Object.fromEntries(searchParams.entries());
  const searchParamsString = JSON.stringify(searchParamsObj);

  const hydrateData = async () => {
    setLoading(true);
    try {
      const workOrderData = await fetchWorkOrders(location?.search);
      setWorkOrders(workOrderData?.work_orders);
      setMetadata(workOrderData?.meta);
      setStatistics(workOrderData?.statistics);
    } catch (err) {
      console.error('Failed to fetch work order data: ', err);
      // TODO: set alert
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location?.search) {
      const today = new Date();
      const date30DaysAgo = new Date(today.setDate(today.getDate() - 30));
      const formattedDate = date30DaysAgo.toISOString().split('T')[0];

      setSearchParams({
        page: searchParamsObj?.page || 0,
        limit: searchParamsObj?.limit || 25,
        min_completed_date: formattedDate,
        ...searchParams,
      });
    } else {
      hydrateData();
    }
  }, [searchParamsString]);

  const updateSearchParams = (newParams, forPagination) => {
    if (forPagination) {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), ...newParams });
    } else {
      setSearchParams(newParams);
    }
  };

  const updatePagination = (newParams) => {
    if (newParams.limit != searchParamsObj?.limit || newParams.page != searchParamsObj?.page) {
      updateSearchParams(newParams, true);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Work Orders</Typography>
        <AddFiltersMenu
          searchParamsObj={searchParamsObj}
          updateSearchParams={updateSearchParams}
          filterConfig={filtersConfiguration}
          resetObj={{ page: searchParamsObj?.page || 0, limit: searchParamsObj?.limit || 25 }}
        />
      </FlexRow>

      {loading ? (
        <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexRow>
      ) : (
        <>
          <TabsWrapper tab={tab} setTab={handleTabChange} />

          {/* Data Grid */}
          {tab === 'workOrders' ? (
            <WorkOrdersDataGrid
              limit={searchParamsObj?.limit}
              page={searchParamsObj?.page}
              workOrders={workOrders}
              updatePagination={updatePagination}
              metadata={metadata}
            />
          ) : (
            <Statistics statistics={statistics} />
          )}
        </>
      )}
    </>
  );
};

const TabsWrapper = ({ tab, setTab }) => (
  <div style={{ width: '100%', marginTop: '8px', borderBottom: `1px solid ${darkTheme.colors.border}` }}>
    <Tabs value={tab} onChange={setTab} sx={{ minHeight: '32px' }}>
      <Tab
        label="Work Orders"
        value="workOrders"
        sx={{ textTransform: 'none', borderRadius: '8px', minHeight: '32px', '&:hover': { backgroundColor: darkTheme.colors.primaryFaded } }}
      />
      <Tab
        label="Statistics"
        value="stats"
        sx={{ textTransform: 'none', borderRadius: '8px', minHeight: '32px', '&:hover': { backgroundColor: darkTheme.colors.primaryFaded } }}
      />
    </Tabs>
  </div>
);

export default WorkOrders;
