import { formatCurrencyForDataGrid, formatDateForDataGrid } from 'constants/formatters';

export const columns = [
  {
    field: 'closed_at',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Closed At',
    flex: 1,
    minWidth: 120,
    type: 'dateTime',
    valueFormatter: ({ value }) => formatDateForDataGrid(value),
  },
  {
    field: 'customer_name',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Customer Name',
    flex: 1,
    minWidth: 220,
    valueGetter: (params) => `${params.row.customer_last_name}, ${params.row.customer_first_name}`,
  },
  {
    field: 'vehicle_stock_number',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Stock #',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'vehicle_condition',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Condition',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'vehicle_year',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Year',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'vehicle_make',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Make',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'vehicle_model',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Model',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'salesperson_name',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Salesperson Name',
    flex: 1,
    minWidth: 220,
    valueGetter: (params) => `${params.row.salesperson_last_name}, ${params.row.salesperson_first_name}`,
  },
  {
    field: 'total_financed_amount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Financed Amount',
    flex: 1,
    minWidth: 130,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'payment_amount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Monthly Payment',
    flex: 1,
    minWidth: 130,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'deal_type',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Type',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'talon_created_at',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Created At',
    flex: 1,
    minWidth: 120,
    type: 'dateTime',
    valueFormatter: ({ value }) => formatDateForDataGrid(value),
  },
  {
    field: 'deal_id',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Talon Deal ID',
    flex: 1,
    minWidth: 100,
  },
];

export const calculateSalesmanStats = (dataObject) => {
  let minKey = null;
  let minValue = Infinity;
  let maxKey = null;
  let maxValue = -Infinity;
  let total = 0;
  let count = 0;

  Object.entries(dataObject).forEach(([key, value]) => {
    // Update min and max values and corresponding keys
    if (value < minValue) {
      minValue = value;
      minKey = key;
    }
    if (value > maxValue) {
      maxValue = value;
      maxKey = key;
    }
    // Calculate total for average computation
    total += value;
    count += 1;
  });

  // Calculate average
  let avgValue = total / count;

  // Construct the result object
  const result = {
    min: `${minKey}: ${minValue}`,
    avg: avgValue,
    max: `${maxKey}: ${maxValue}`,
  };

  return result;
};

export const calculateStatsAndReduceCategories = (dataObject, maxBuckets = 10) => {
  let min = Infinity;
  let max = -Infinity;
  let total = 0;
  let count = 0;

  // Find min, max, and calculate total for average
  Object.values(dataObject).forEach((value) => {
    if (value < min) min = value;
    if (value > max) max = value;
    total += value;
    count++;
  });

  const avg = count > 0 ? total / count : 0;

  // Sort entries by value and keep only the top ones
  const sortedEntries = Object.entries(dataObject).sort((a, b) => b[1] - a[1]);
  const topEntries = sortedEntries.slice(0, maxBuckets - 1);
  const otherEntries = sortedEntries.slice(maxBuckets - 1);

  let countByBucket = topEntries.reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});

  // Sum values of all other entries if there are more than max buckets
  if (sortedEntries.length > maxBuckets) {
    const otherTotal = otherEntries.reduce((sum, [, value]) => sum + value, 0);
    countByBucket['OTHER'] = otherTotal;
  } else {
    // If there are maxBuckets or fewer, include all entries without "OTHER"
    otherEntries.forEach(([key, value]) => {
      countByBucket[key] = value;
    });
  }

  return {
    min: min == Infinity ? 0 : min,
    avg,
    max: max == -Infinity ? 0 : max,
    count_by_bucket: countByBucket,
  };
};
