import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import routes from './Routes.js';
import { darkTheme } from './constants/styles';
import { AppProvider } from 'AppContext.js';

import './index.css';

const { colors, typography } = darkTheme;

let theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      default: colors.background,
      paper: colors.surface,
    },
    text: {
      primary: colors.text,
    },
  },

  typography: {
    fontFamily: typography.fontFamily,
    color: colors.text,
  },

  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiDataGrid-cell': {
            borderColor: colors.border,
          },
          '& .MuiDataGrid-columnHeaders': {
            borderColor: colors.border,
          },
          '& .MuiDataGrid-footerContainer': {
            borderColor: colors.border,
          },
          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        },
        columnHeaders: {
          color: colors.primary,
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AppProvider>
        <RouterProvider router={routes} />
      </AppProvider>
    </LocalizationProvider>
  </ThemeProvider>,
);
