import { createBrowserRouter } from 'react-router-dom';

import App from './App';
import { AppProtection, AdminProtection } from 'components/ProtectedRoute'; // TODO: Add admin and protect.
import ErrorRoute from 'views/Error';
import Login from 'views/Login';
import Vehicles from 'views/Vehicles';
import VehicleDeals from 'views/VehicleDeals';
import WorkOrders from 'views/WorkOrders';
import ROUTE_MODEL from 'constants/routeModel';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorRoute />, // TODO: Put something better here
    children: [
      {
        index: true,
        element: <AppProtection />,
      },
      {
        path: ROUTE_MODEL.LOGIN.PATH, // '/login'
        element: <Login />,
      },
      {
        path: 'app', // '/app'
        element: <AppProtection />,
        children: [
          {
            index: true,
            element: <div>Dashboard</div>,
          },
          {
            index: true,
            path: ROUTE_MODEL.DASHBOARD.RELATIVE_PATH, // '/app/dashboard'
            element: <div>Dashboard</div>,
          },
          {
            path: ROUTE_MODEL.VEHICLES.RELATIVE_PATH, // '/app/vehicles'
            element: <Vehicles />,
            children: [
              {
                path: ROUTE_MODEL.VEHICLES.VEHICLE_DETAIL.RELATIVE_PATH, // '/app/vehicles/:id'
                element: <div>Vehicle Detail</div>,
              },
            ],
          },
          {
            path: ROUTE_MODEL.DEALS.RELATIVE_PATH, // '/app/deals'
            element: <VehicleDeals />,
            children: [
              {
                path: ROUTE_MODEL.DEALS.DEAL_DETAIL.RELATIVE_PATH, // '/app/vehicles/:id'
                element: <div>Deal Detail</div>,
              },
            ],
          },
          {
            path: ROUTE_MODEL.WORK_ORDERS.RELATIVE_PATH, // '/app/work-orders'
            element: <WorkOrders />,
            children: [
              {
                path: ROUTE_MODEL.WORK_ORDERS.WORK_ORDER_DETAIL.RELATIVE_PATH, // '/app/work-orders/:id'
                element: <div>Work Order Detail</div>,
              },
            ],
          },
          {
            path: ROUTE_MODEL.ACCOUNT.RELATIVE_PATH, // '/app/my-account'
            element: <div>My Account</div>,
          },
        ],
      },
    ],
  },
]);

export default routes;
