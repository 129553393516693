import { Box, Typography } from '@mui/material';
import { PieChart as RePieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

import { darkTheme } from 'constants/styles';
import { Card } from 'components/Layouts';

const COLORS = [darkTheme.colors.primaryFaded, darkTheme.colors.secondaryFaded];
const STROKE_COLORS = [darkTheme.colors.primary, darkTheme.colors.secondary];

export const BooleanPieChart = ({ height = 250, dataset }) => {
  return (
    <Box sx={{ width: '100%', boxSizing: 'border-box', paddingTop: '20px' }}>
      <ResponsiveContainer width="100%" height={height}>
        <RePieChart>
          <Pie
            style={{ outline: 'none' }}
            data={dataset}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label={{ fill: 'white', fontFamily: darkTheme.typography.fontFamily }}
          >
            {dataset.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} stroke={STROKE_COLORS[index % STROKE_COLORS.length]} strokeWidth={2} />
            ))}
          </Pie>
          <Legend
            formatter={(value, entry) => {
              const { color } = entry;
              return <span style={{ color: color.replace('0.2', '1'), fontFamily: darkTheme.typography.fontFamily }}>{value}</span>;
            }}
          />
        </RePieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export const BooleanPieChartCard = ({ title, dataset }) => {
  return (
    <Card>
      <Typography variant="h5">{title}</Typography>

      <BooleanPieChart height={270} dataset={dataset} />
    </Card>
  );
};

export default BooleanPieChart;
