import { Chip, TextField, Autocomplete } from '@mui/material';

export default ({ handleChange = () => null, value = [], label = 'Set the label', margin = '0px', options = [] }) => {
  return (
    <Autocomplete
      onChange={handleChange}
      style={{ margin }}
      limitTags={2}
      value={value}
      multiple
      options={options}
      freeSolo
      fullWidth
      size="small"
      renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="filled" label={option} size="small" {...getTagProps({ index })} />)}
      renderInput={(params) => <TextField {...params} variant="filled" label={label} />}
    />
  );
};
