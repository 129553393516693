import styled from 'styled-components';

import { darkTheme } from '../../constants/styles';

export const SidebarContainer = styled.div`
  width: 264px;
  z-index: 1;
  position: fixed;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: 12px 12px;
`;

export const Sidebar = styled.div`
  width: 240px;
  z-index: 1;
  position: fixed;
  box-sizing: border-box;
  height: calc(100% - 24px);
  overflow: hidden;
  background: ${darkTheme.colors.surface};
  border-radius: 8px;
`;

export const LogoContainer = styled.div`
  padding: 0px;
  margin: 0px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
`;

export const NavContainer = styled.div`
  padding: 8px;
  margin: 0px;
  height: calc(100% - 80px - 110px);
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AccountAndLogoutContainer = styled.div`
  padding: 8px;
  margin: 0px;
  height: 110px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  gap: 8px;
`;

export const SidebarItemIconContainer = styled.div`
  transition: all 0.2s ease-in-out;

  ${({ isActive }) =>
    isActive &&
    `
    color: ${darkTheme.colors.primary};
  `}
`;

export const SidebarItemContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 16px;
  gap: 16px;
  border-radius: 8px;
  cursor: pointer;
  color: ${darkTheme.colors.text};
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  ${({ isActive }) =>
    isActive &&
    `
    color: ${darkTheme.colors.primary};
    background: ${darkTheme.colors.background};
  `}

  &:hover {
    background: ${darkTheme.colors.background};
  }
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${darkTheme.colors.surface};
  color: ${darkTheme.colors.text};
  padding: 8px;
  z-index: 100;
  box-sizing: border-box;
  height: 50px;
  border-radius: 50px;
`;
