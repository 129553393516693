import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';

import { AppContext } from 'AppContext';
import ROUTE_MODEL from 'constants/routeModel';

export const AppProtection = () => {
  const { appState } = useContext(AppContext);
  const { user, appLoading } = appState;
  const location = useLocation();

  if (!user || !user.id) {
    if (!appLoading) {
      return <Navigate to={ROUTE_MODEL.LOGIN.PATH} />;
    }
  } else {
    if (location.pathname === '/') {
      return <Navigate to={ROUTE_MODEL.DASHBOARD.PATH} />;
    }
  }

  return <Outlet />;
};

export const TypeProtection = ({ type }) => {
  // TODO: Implement
  // const user = useSelector((state) => state.user);

  // if (!!user?.active_profile?.id) {
  //   const entityType = user.active_profile.dealer ? 'dealer' : 'lender';

  //   if (entityType === type) {
  //     return <Outlet />;
  //   } else {
  //     return <Navigate to={`/app/${entityType}/dashboard`} />;
  //   }
  // }

  return <Outlet />;
};

export const AdminProtection = () => {
  // TODO: Implement
  // const user = useSelector((state) => state.user);

  // if (user.active_profile.is_admin) {
  //   return <Outlet />;
  // } else {
  //   const entityType = user.active_profile.dealer ? 'dealer' : 'lender';
  //   return <Navigate to={`/app/${entityType}/dashboard`} />;
  // }

  return <Outlet />;
};

export const SuperAdminProtection = () => {
  // TODO: Implement
  // const user = useSelector((state) => state.user);
  // const app = useSelector((state) => state.app);

  // if (!user || !user.id) {
  //   if (!app.loading) {
  //     return <Navigate to="/login" />;
  //   }
  // } else if (user.is_super_admin) {
  //   return <SuperAdmin />;
  // } else {
  //   return <Navigate to="/login" />;
  // }

  return <Outlet />;
};
