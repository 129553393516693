import { WorkOrdersAPI } from 'store/api';

export const fetchWorkOrders = async (queryParameters) => {
  try {
    const response = await WorkOrdersAPI.getWorkOrders(queryParameters);

    return response;
  } catch (err) {
    console.log(`Error fetching work orders: ${err}`);
  }
};

export const fetchWorkOrderDetails = async (identifier) => {
  try {
    const response = await WorkOrdersAPI.getWorkOrderDetails(identifier);

    return response;
  } catch (err) {
    console.log(`Error fetching work order detail for identifier ${identifier}: ${err}`);
  }
};

export default {
  fetchWorkOrderDetails,
  fetchWorkOrders,
};
