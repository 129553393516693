import { Navigate } from 'react-router-dom';

const ErrorRoute = () => {
  // TODO: Implement
  // const user = useSelector((state) => state.user);

  // if (!user || !user.id) {
  //   return <Navigate to="/login" />;
  // }

  // const entityType = user.active_profile?.dealer ? 'dealer' : 'lender';

  // if (!!entityType) {
  //   return <Navigate to={`/app/${entityType}/dashboard`} />;
  // } else {
  //   return <Navigate to="/login" />;
  // }

  return <>Error Route</>;
};

export default ErrorRoute;
