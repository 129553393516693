import React, { useEffect, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Alert as MuiAlert, Snackbar, Backdrop, CircularProgress, Typography } from '@mui/material';
import styled from 'styled-components';

import AppSidebar, { MobileHeadbar } from './components/AppSidebar';
import { FlexRow, MainContentContainer } from './components/Layouts';
import { AppContext } from './AppContext';
import { darkTheme } from 'constants/styles';
import { getSession } from 'store/actions/auth';

const AppContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: ${darkTheme.colors.background};
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const { appState, updateWindowSize, updateAlert, updateAppLoading, updateUser } = useContext(AppContext);

  useEffect(() => {
    function handleWindowResize() {
      updateWindowSize(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowResize);

    if (!appState?.user?.id) {
      const fetchData = async () => {
        try {
          updateAppLoading(true);
          const { user } = await getSession();
          if (user?.id) {
            updateUser(user);
          } else {
            updateAlert({
              type: 'error',
              message: 'No active session found. Please login again.',
              showing: true,
            });
          }
        } catch (err) {
          updateAlert({
            type: 'error',
            message: 'No active session found. Please login again.',
            showing: true,
          });
        } finally {
          updateAppLoading(false);
        }
      };

      fetchData();
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleAlertClose = () => {
    updateAlert({
      type: '',
      message: '',
      showing: false,
    });
  };

  const mobileView = appState?.windowSize < 900;

  return (
    <AppContainer>
      {mobileView ? <MobileHeadbar /> : <AppSidebar />}

      <MainContentContainer mobileView={mobileView}>
        <Outlet />
      </MainContentContainer>

      <Backdrop sx={{ color: '#FFF', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={appState.appLoading}>
        <FlexRow padding="0" margin="0" justifyContent="center" alignItems="center" flexColumn>
          <CircularProgress color="primary" />
          <Typography sx={{ marginTop: '10px' }} variant="overline">
            {appState.loadingMessage || ''}
          </Typography>
        </FlexRow>
      </Backdrop>

      <Snackbar open={appState?.alert?.showing} autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={appState?.alert?.type} sx={{ width: '100%' }}>
          {appState?.alert?.message}
        </Alert>
      </Snackbar>
    </AppContainer>
  );
}

export default App;
