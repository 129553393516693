import { useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2.js';

import { currencyFormatterNoDecimal, numberFormatterNoCommas } from 'constants/formatters';
import { MinMaxAvgChart } from 'components/Charts/BarChart';
import { BooleanPieChartCard } from 'components/Charts/BooleanPieChart';
import { calculateSalesmanStats, calculateStatsAndReduceCategories } from './helpers';

const Statistics = ({ statistics }) => {
  const countByCity = useMemo(
    () => calculateStatsAndReduceCategories(statistics.customer_data?.count_by_city || {}),
    [JSON.stringify(statistics.customer_data?.count_by_city)],
  );
  const countByStates = useMemo(
    () => calculateStatsAndReduceCategories(statistics.customer_data?.count_by_state || {}),
    [JSON.stringify(statistics.customer_data?.count_by_state)],
  );
  const countByZip = useMemo(
    () => calculateStatsAndReduceCategories(statistics.customer_data?.count_by_zip_code || {}),
    [JSON.stringify(statistics.customer_data?.count_by_zip_code)],
  );
  const countByMake = useMemo(
    () => calculateStatsAndReduceCategories(statistics.vehicle_data?.count_by_make || {}),
    [JSON.stringify(statistics.vehicle_data?.count_by_make)],
  );
  const countByModel = useMemo(
    () => calculateStatsAndReduceCategories(statistics.vehicle_data?.count_by_model || {}),
    [JSON.stringify(statistics.vehicle_data?.count_by_model)],
  );
  const salesmanStats = useMemo(
    () => calculateSalesmanStats(statistics.salesperson_data?.count_by_salesperson || {}),
    [JSON.stringify(statistics.salesperson_data?.count_by_salesperson)],
  );

  return (
    <Grid container spacing={2} style={{ overflowY: 'auto', paddingTop: '10px', marginTop: '0px' }}>
      <Grid xs={3}>
        <BooleanPieChartCard
          title="Condition"
          dataset={[
            { name: 'New', value: statistics.vehicles_sold?.new },
            { name: 'Used', value: statistics.vehicles_sold?.used },
          ]}
        />
      </Grid>
      <Grid xs={3}>
        <BooleanPieChartCard
          title="Funding Source"
          dataset={[
            { name: 'Financed', value: statistics.vehicles_sold?.financed },
            { name: 'Cash', value: statistics.vehicles_sold?.cash },
          ]}
        />
      </Grid>
      <Grid xs={3}>
        <BooleanPieChartCard
          title="Trade Ins"
          dataset={[
            { name: 'Trade', value: statistics.vehicles_sold?.with_trade },
            { name: 'No Trade', value: statistics.vehicles_sold?.total - statistics.vehicles_sold?.with_trade },
          ]}
        />
      </Grid>
      <Grid xs={3}>
        <BooleanPieChartCard
          title="With ESP"
          dataset={[
            { name: 'With ESP', value: statistics.vehicles_sold?.with_esp_sold },
            { name: 'No ESP', value: statistics.vehicles_sold?.total - statistics.vehicles_sold?.with_esp_sold },
          ]}
        />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart transformType="valueSort" title="Cities" stats={countByCity} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart transformType="valueSort" title="States" stats={countByStates} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart transformType="valueSort" title="Zip Codes" stats={countByZip} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart transformType="valueSort" title="Makes" stats={countByMake} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart transformType="valueSort" title="Models" stats={countByModel} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart
          transformType="valueSort"
          title="Salesperson"
          stats={{
            ...salesmanStats,
            count_by_bucket: statistics.salesperson_data?.count_by_salesperson,
          }}
          overrideFormatters={['min', 'max']}
        />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Add on Amount" stats={statistics.add_on_amount_if_sold} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart title="APR" stats={statistics.apr_if_financed} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart title="Credit Score" stats={statistics.customer_data?.credit_score_if_financed} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Deal Amount" stats={statistics.deal_amount} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Down Payment" stats={statistics.down_payment_if_financed} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="ESP Amount" stats={statistics.esp_amount_if_sold} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Financed Amount" stats={statistics.financed_amount_if_financed} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart title="Mileage" stats={statistics.mileage} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Monthly Payment" stats={statistics.monthly_payment_if_financed} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Retail Price" stats={statistics.retail_price} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart title="Term" stats={statistics.term_if_financed} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Trade ACV" stats={statistics.trade_acv_if_trade_exists} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Trade Allowance" stats={statistics.trade_allowance_if_trade_exists} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={currencyFormatterNoDecimal} title="Trade Payoff" stats={statistics.trade_payoff_if_trade_exists} />
      </Grid>
      <Grid xs={6}>
        <MinMaxAvgChart formatter={numberFormatterNoCommas} title="Model Year" stats={statistics.year} transformType="keySort" />
      </Grid>
    </Grid>
  );
};

export default Statistics;
