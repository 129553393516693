import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Drawer, Typography } from '@mui/material';
import { TwoWheeler, Build, Dashboard, Person, Logout, LocalOffer } from '@mui/icons-material';

import { SidebarItemContainer, SidebarItemIconContainer } from 'components/AppSidebar/styled-components';
import ROUTE_MODEL from 'constants/routeModel';
import { logout } from 'store/actions/auth';
import { AppContext } from 'AppContext';

const menuConfiguration = [
  {
    icon: <Dashboard />,
    label: 'Dashboard',
    link: ROUTE_MODEL.DASHBOARD.PATH,
  },
  {
    icon: <TwoWheeler />,
    label: 'Vehicles',
    link: ROUTE_MODEL.VEHICLES.PATH,
  },
  {
    icon: <LocalOffer />,
    label: 'Deals',
    link: ROUTE_MODEL.DEALS.PATH,
  },
  {
    icon: <Build />,
    label: 'Work Orders',
    link: ROUTE_MODEL.WORK_ORDERS.PATH,
  },
];

export const SideBarMenu = ({ onSelect }) => {
  return (
    <>
      {menuConfiguration.map((item) => {
        return (
          <NavLink key={item.label} style={{ width: '100%' }} to={item.link} onClick={onSelect ? () => onSelect() : null}>
            {({ isActive }) => (
              <SidebarItemContainer isActive={isActive}>
                <SidebarItemIconContainer isActive={isActive}>{item.icon}</SidebarItemIconContainer>
                <Typography variant="body1">{item.label}</Typography>
              </SidebarItemContainer>
            )}
          </NavLink>
        );
      })}
    </>
  );
};

export const AccountAndLogout = ({ onSelect }) => {
  const { updateUser } = useContext(AppContext);

  return (
    <>
      <NavLink style={{ width: '100%' }} to={ROUTE_MODEL.ACCOUNT.PATH} onClick={onSelect ? () => onSelect() : null}>
        {({ isActive }) => (
          <SidebarItemContainer isActive={isActive}>
            <SidebarItemIconContainer isActive={isActive}>
              <Person />
            </SidebarItemIconContainer>

            <Typography variant="body1">My Account</Typography>
          </SidebarItemContainer>
        )}
      </NavLink>

      <SidebarItemContainer
        onClick={() => {
          if (onSelect) onSelect();
          logout();
          updateUser({});
        }}
      >
        <SidebarItemIconContainer>
          <Logout />
        </SidebarItemIconContainer>

        <Typography variant="body1">Logout</Typography>
      </SidebarItemContainer>
    </>
  );
};

export const MobileMenu = ({ drawerOpen, setDrawerOpen }) => {
  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      sx={{
        width: 250,
        '& .MuiDrawer-paper': {
          width: 250,
          boxSizing: 'border-box',
        },
      }}
    >
      <div style={{ padding: '20px 10px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <SideBarMenu onSelect={() => setDrawerOpen(false)} />
      </div>
    </Drawer>
  );
};

export const MobileAvatarMenu = ({ drawerOpen, setDrawerOpen }) => {
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      sx={{
        width: 250,
        '& .MuiDrawer-paper': {
          width: 250,
          boxSizing: 'border-box',
        },
      }}
    >
      <div style={{ padding: '20px 10px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <AccountAndLogout onSelect={() => setDrawerOpen(false)} />
      </div>
    </Drawer>
  );
};
