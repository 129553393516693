import { useState, useEffect } from 'react';
import { Tab, Tabs, CircularProgress, Typography } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FlexRow } from 'components/Layouts';
import { fetchVehicles } from 'store/actions/vehicles';
import { darkTheme } from 'constants/styles';
import AddFiltersMenu, { TYPES } from 'components/AddFiltersMenu';
import DataGrid from './DataGrid';
import Statistics from './Statistics';
import { INVENTORY_STATUS } from './helpers';
import { MAKES } from 'constants/vehicleAttributes';

const filtersConfiguration = [
  { attribute: 'stock_number', type: TYPES.MULTI_INPUT, label: 'Stock Numbers' },
  { attributes: ['min_delivery_date', 'max_delivery_date'], type: TYPES.DATE_RANGE_PICKER, labels: ['Min. Date', 'Max Date', 'Delivery Date'] },
  { attribute: 'vin', type: TYPES.MULTI_INPUT, label: 'VINs' },
  { attributes: ['min_ubi_start_date', 'max_ubi_start_date'], type: TYPES.DATE_RANGE_PICKER, labels: ['Min. Date', 'Max Date', 'UBI Start Date'] },
  { attribute: 'make', type: TYPES.MULTI_INPUT, label: 'Makes', options: MAKES },
  { attributes: ['min_ubi_end_date', 'max_ubi_end_date'], type: TYPES.DATE_RANGE_PICKER, labels: ['Min. Date', 'Max Date', 'UBI End Date'] },
  { attribute: 'year', type: TYPES.MULTI_INPUT, label: 'Years' },
  { attributes: ['min_price', 'max_price'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min. Price', 'Max Price', 'Price'] },
  { attribute: 'inventory_status', type: TYPES.MULTI_INPUT, label: 'Status', options: INVENTORY_STATUS },
  { attributes: ['min_cost', 'max_cost'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min. Cost', 'Max Cost', 'Cost'] },
  { attributes: ['min_ubi_cost', 'max_ubi_cost'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min. Cost', 'Max Cost', 'UBI Cost'] },
  { attributes: ['min_days_to_ubi', 'max_days_to_ubi'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min. Days', 'Max Days', 'Days to UBI'] },
  { attributes: ['min_age', 'max_age'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min. Days', 'Max Days', 'Age'] },
  { attributes: ['min_days_to_sale', 'max_days_to_sale'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min. Days', 'Max Days', 'Days to Sale'] },
  { attribute: 'condition', type: TYPES.MULTI_SELECT, label: 'Condition', options: ['New', 'Used'] },
  { attribute: 'ubi_complete', type: TYPES.MULTI_SELECT, label: 'UBI Complete', options: ['True', 'False'] },
  { attribute: 'in_stock', type: TYPES.MULTI_SELECT, label: 'In Stock', options: ['True', 'False'] },
];

const Vehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [statistics, setStatistics] = useState({});
  const [tab, setTab] = useState('vehicles'); // vehicles || stats
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const searchParamsObj = Object.fromEntries(searchParams.entries());
  const searchParamsString = JSON.stringify(searchParamsObj);

  const hydrateData = async () => {
    setLoading(true);
    try {
      const vehicleData = await fetchVehicles(location?.search);
      setVehicles(vehicleData?.vehicles);
      setMetadata(vehicleData?.meta);
      setStatistics(vehicleData?.statistics);
    } catch (err) {
      console.error('Failed to fetch vehicle data: ', err);
      // TODO: set alert
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location?.search) {
      setSearchParams({
        in_stock: 'True',
        page: searchParamsObj?.page || 0,
        limit: searchParamsObj?.limit || 25,
        ...searchParams,
      });
    } else {
      hydrateData();
    }
  }, [searchParamsString]);

  const updateSearchParams = (newParams, forPagination) => {
    if (forPagination) {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), ...newParams });
    } else {
      setSearchParams(newParams);
    }
  };

  const updatePagination = (newParams) => {
    if (newParams.limit != searchParamsObj?.limit || newParams.page != searchParamsObj?.page) {
      updateSearchParams(newParams, true);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Vehicles</Typography>
        <AddFiltersMenu
          searchParamsObj={searchParamsObj}
          updateSearchParams={updateSearchParams}
          filterConfig={filtersConfiguration}
          resetObj={{ in_stock: 'True', page: searchParamsObj?.page || 0, limit: searchParamsObj?.limit || 25 }}
        />
      </FlexRow>

      {loading ? (
        <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexRow>
      ) : (
        <>
          <TabsWrapper tab={tab} setTab={handleTabChange} />

          {/* Data Grid */}
          {tab === 'vehicles' ? (
            <DataGrid limit={searchParamsObj?.limit} page={searchParamsObj?.page} vehicles={vehicles} updatePagination={updatePagination} metadata={metadata} />
          ) : (
            <Statistics statistics={statistics} />
          )}
        </>
      )}
    </>
  );
};

const TabsWrapper = ({ tab, setTab }) => (
  <div style={{ width: '100%', marginTop: '8px', borderBottom: `1px solid ${darkTheme.colors.border}` }}>
    <Tabs value={tab} onChange={setTab} sx={{ minHeight: '32px' }}>
      <Tab
        label="Vehicles"
        value="vehicles"
        sx={{ textTransform: 'none', borderRadius: '8px', minHeight: '32px', '&:hover': { backgroundColor: darkTheme.colors.primaryFaded } }}
      />
      <Tab
        label="Statistics"
        value="stats"
        sx={{ textTransform: 'none', borderRadius: '8px', minHeight: '32px', '&:hover': { backgroundColor: darkTheme.colors.primaryFaded } }}
      />
    </Tabs>
  </div>
);

export default Vehicles;
