import { formatCurrencyForDataGrid } from 'constants/formatters';

export const columns = [
  {
    field: 'stock_number',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Stock #',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'condition',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Condition',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'year',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Year',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'make',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Make',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'model',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Model',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'in_stock',
    headerAlign: 'left',
    align: 'left',
    headerName: 'In Stock',
    flex: 1,
    minWidth: 100,
    type: 'boolean',
  },
  {
    field: 'inventory_status',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Status',
    flex: 1,
    minWidth: 140,
  },
  {
    field: 'total_price',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Price',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 100,
  },
  {
    field: 'total_cost',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Cost',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 100,
  },
  {
    field: 'ubi_complete',
    headerAlign: 'left',
    align: 'left',
    headerName: 'UBI Complete',
    flex: 1,
    minWidth: 120,
    type: 'boolean',
  },
  {
    field: 'ubi_cost',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'UBI Cost',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 100,
  },
  {
    field: 'days_to_ubi',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Days to UBI',
    flex: 1,
    minWidth: 120,
  },
  {
    field: 'days_to_sale',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Days to Sale',
    flex: 1,
    minWidth: 120,
  },
  {
    field: 'age',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Age',
    flex: 1,
    minWidth: 100,
  },
];

export const INVENTORY_STATUS = ['Available for sale', 'Billed to customer', 'Committed to customer', 'Deleted', 'Issued', 'Trade/Transfer'];
