import * as React from 'react';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { parse, format, isValid, subYears } from 'date-fns';
import { Typography } from '@mui/material';

// Function to convert to yyyy-MM-dd, ensuring the date is complete and within the last 100 years
function convertToYYYYMMDD(inputDate) {
  try {
    // Use the Date constructor to parse the full date string
    const parsedDate = new Date(inputDate);

    // Validate the date and ensure it's within the last 10 years
    const currentDate = new Date();
    const hundredYearsAgo = subYears(currentDate, 10);

    if (isValid(parsedDate) && parsedDate >= hundredYearsAgo) {
      // Format and return the date in 'yyyy-MM-dd' format if it's valid and within the range
      return format(parsedDate, 'yyyy-MM-dd');
    }
  } catch (error) {
    console.error('Error converting date:', error);
  }

  // Return null if the date is invalid or not within the desired range
  return null;
}

// Function to convert from yyyy-MM-dd back to a more complete date string
function convertFromYYYYMMDD(inputDate) {
  try {
    const parsedDate = parse(inputDate, 'yyyy-MM-dd', new Date());
    if (isValid(parsedDate)) {
      // Note: The original format includes timezone and day of the week, which we can't accurately recreate from yyyy-MM-dd
      return parsedDate;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error converting date:', error);
  }

  return null; // Return null if the date is invalid
}

export const CustomDatePicker = ({ label, value, handleChange }) => {
  const formattedForPicker = convertFromYYYYMMDD(value);
  return (
    <MUIDatePicker
      label={label}
      value={formattedForPicker}
      onChange={(newValue) => {
        const formattedForAPI = convertToYYYYMMDD(newValue);
        if (formattedForAPI) {
          handleChange(formattedForAPI);
        }
      }}
      slotProps={{
        textField: {
          variant: 'filled',
          size: 'small',
        },
        field: {
          clearable: true,
          onClear: () => handleChange(null),
        },
      }}
    />
  );
};

export const DateRangePicker = ({ startingLabel, startingDate, endingDate, endingLabel, handleStartingChange, handleEndingChange }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box' }}>
      <CustomDatePicker label={startingLabel} value={startingDate} handleChange={handleStartingChange} />
      <Typography sx={{ m: '0px 5px' }}>-</Typography>
      <CustomDatePicker label={endingLabel} value={endingDate} handleChange={handleEndingChange} />
    </div>
  );
};
