import { useState, useEffect } from 'react';
import { Tab, Tabs, CircularProgress, Typography } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FlexRow } from 'components/Layouts';
import { fetchVehicleDeals } from 'store/actions/vehicleDeals';
import { darkTheme } from 'constants/styles';
import AddFiltersMenu, { TYPES } from 'components/AddFiltersMenu';
import DataGrid from './DataGrid';
import Statistics from './Statistics';
import { MAKES } from 'constants/vehicleAttributes';

const filtersConfiguration = [
  // DEAL
  { attribute: 'deal_type', type: TYPES.MULTI_SELECT, label: 'Deal Type', options: ['financed', 'cash'] },

  // VEHICLE
  { attribute: 'vehicle_condition', type: TYPES.MULTI_SELECT, label: 'Condition', options: ['New', 'Used'] },
  { attribute: 'vehicle_vin', type: TYPES.MULTI_INPUT, label: 'VINs' },
  { attribute: 'vehicle_stock_number', type: TYPES.MULTI_INPUT, label: 'Stock Numbers' },
  { attribute: 'vehicle_year', type: TYPES.MULTI_INPUT, label: 'Years' },
  { attribute: 'vehicle_make', type: TYPES.MULTI_INPUT, label: 'Makes', options: MAKES },

  // CUSTOMER
  { attribute: 'customer_city', type: TYPES.MULTI_INPUT, label: 'Customer City' },
  { attribute: 'customer_state', type: TYPES.MULTI_INPUT, label: 'Customer State' },
  { attribute: 'customer_zip', type: TYPES.MULTI_INPUT, label: 'Customer Zip' },

  // Salesperson
  { attribute: 'salesperson_employee_code', type: TYPES.MULTI_INPUT, label: 'Salesperson Code' },

  // Dates
  { attributes: ['min_talon_created_at', 'max_talon_created_at'], type: TYPES.DATE_RANGE_PICKER, labels: ['Min. Date', 'Max Date', 'Created Date'] },
  { attributes: ['min_contracted_at', 'max_contracted_at'], type: TYPES.DATE_RANGE_PICKER, labels: ['Min. Date', 'Max Date', 'Contract Date'] },
  { attributes: ['min_closed_at', 'max_closed_at'], type: TYPES.DATE_RANGE_PICKER, labels: ['Min. Date', 'Max Date', 'Closed Date'] },

  // Numbers
  { attributes: ['min_apr', 'max_apr'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min. APR', 'Max APR', 'APR'] },
  { attributes: ['min_payment_amount', 'max_payment_amount'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min. Payment', 'Max Payment', 'Payment'] },
  { attributes: ['min_retail_price_amount', 'max_retail_price_amount'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min. Price', 'Max Price', 'Unit Price'] },
  { attributes: ['min_financed_amount', 'max_financed_amount'], type: TYPES.INTEGER_RANGE_PICKER, labels: ['Min. Amount', 'Max Amount', 'Financed Amount'] },
  {
    attributes: ['min_customer_credit_score', 'max_customer_credit_score'],
    type: TYPES.INTEGER_RANGE_PICKER,
    labels: ['Min. Score', 'Max Score', 'Credit Score'],
  },
];

// TODO: Create a percentage and a currency picker.
// TODO: Create constants for cities, states, zips (or better yet, query from the db. Same for makes.)
const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [statistics, setStatistics] = useState({});
  const [tab, setTab] = useState('deals'); // deals || stats
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const searchParamsObj = Object.fromEntries(searchParams.entries());
  const searchParamsString = JSON.stringify(searchParamsObj);

  const hydrateData = async () => {
    setLoading(true);
    try {
      const dealsData = await fetchVehicleDeals(location?.search);
      setDeals(dealsData?.deals);
      setMetadata(dealsData?.meta);
      setStatistics(dealsData?.statistics);
    } catch (err) {
      console.error('Failed to fetch deals data: ', err);
      // TODO: set alert
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location?.search) {
      setSearchParams({
        page: searchParamsObj?.page || 0,
        limit: searchParamsObj?.limit || 25,
        ...searchParams,
      });
    } else {
      hydrateData();
    }
  }, [searchParamsString]);

  const updateSearchParams = (newParams, forPagination) => {
    if (forPagination) {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), ...newParams });
    } else {
      setSearchParams(newParams);
    }
  };

  const updatePagination = (newParams) => {
    if (newParams.limit != searchParamsObj?.limit || newParams.page != searchParamsObj?.page) {
      updateSearchParams(newParams, true);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Deals</Typography>
        <AddFiltersMenu
          searchParamsObj={searchParamsObj}
          updateSearchParams={updateSearchParams}
          filterConfig={filtersConfiguration}
          resetObj={{ page: searchParamsObj?.page || 0, limit: searchParamsObj?.limit || 25 }}
        />
      </FlexRow>

      {loading ? (
        <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexRow>
      ) : (
        <>
          <TabsWrapper tab={tab} setTab={handleTabChange} />

          {/* Data Grid */}
          {tab === 'deals' ? (
            <DataGrid limit={searchParamsObj?.limit} page={searchParamsObj?.page} deals={deals} updatePagination={updatePagination} metadata={metadata} />
          ) : (
            <Statistics statistics={statistics} />
          )}
        </>
      )}
    </>
  );
};

const TabsWrapper = ({ tab, setTab }) => (
  <div style={{ width: '100%', marginTop: '8px', borderBottom: `1px solid ${darkTheme.colors.border}` }}>
    <Tabs value={tab} onChange={setTab} sx={{ minHeight: '32px' }}>
      <Tab
        label="Deals"
        value="deals"
        sx={{ textTransform: 'none', borderRadius: '8px', minHeight: '32px', '&:hover': { backgroundColor: darkTheme.colors.primaryFaded } }}
      />
      <Tab
        label="Statistics"
        value="stats"
        sx={{ textTransform: 'none', borderRadius: '8px', minHeight: '32px', '&:hover': { backgroundColor: darkTheme.colors.primaryFaded } }}
      />
    </Tabs>
  </div>
);

export default Deals;
