import * as React from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

export default function CheckboxLabels({ options = [], handleChange, value = [] }) {
  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          checked={value?.includes(option)}
          key={option}
          control={<Checkbox />}
          label={option}
          onChange={(event, checked) => handleChange(option, checked)}
        />
      ))}
    </FormGroup>
  );
}
