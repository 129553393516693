import { METHODS, request } from 'services/axios';

const URIS = {
  GET_WORK_ORDERS: (queryString) => (queryString ? `/work-orders${queryString}` : '/work-orders'),
  GET_WORK_ORDER_DETAIL: (identifier) => `/work-orders/${identifier}`,
};

export default {
  getWorkOrders: (queryString) =>
    request({
      url: URIS.GET_WORK_ORDERS(queryString),
      method: METHODS.GET,
    }),

  getWorkOrderDetails: (identifier) =>
    request({
      url: URIS.GET_WORK_ORDER_DETAIL(identifier),
      method: METHODS.GET,
    }),
};
