import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IconButton, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

import logo from './timmyHorizonTransparent.png';
import { AccountAndLogout, MobileAvatarMenu, MobileMenu, SideBarMenu } from './menu';
import { Sidebar, LogoContainer, NavContainer, MobileHeader, AccountAndLogoutContainer, SidebarContainer } from './styled-components';
import { FlexRow } from 'components/Layouts';
import { darkTheme } from 'constants/styles';
import ROUTE_MODEL from 'constants/routeModel';

export const MobileHeadbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [accountDrawerOpen, setAccountDrawerOpen] = useState(false);

  return (
    <div style={{ padding: '20px 20px', boxSizing: 'border-box', width: '100%', height: '70px', zIndex: 1 }}>
      <MobileHeader>
        {/* Left menu icon */}
        <div>
          <IconButton
            size="small"
            onClick={() => setDrawerOpen(!drawerOpen)}
            sx={{
              ml: '10px',
            }}
          >
            <MenuIcon sx={{ width: 30, height: 30, color: darkTheme.colors.text }} />
          </IconButton>

          <MobileMenu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        </div>

        {/* Central logo */}
        <Link to="/">
          <FlexRow padding="0" margin="0" justifyContent="center" alignItems="center">
            <img style={{ height: '40px' }} src={logo} alt="logo" aria-label="Logo" />
          </FlexRow>
        </Link>

        {/* Right avatar menu */}
        <div>
          <IconButton onClick={() => setAccountDrawerOpen(!accountDrawerOpen)} size="small" color="white" sx={{ mr: '10px' }}>
            <Avatar sx={{ width: 30, height: 30, background: darkTheme.colors.text, color: darkTheme.colors.background }} />
          </IconButton>

          <MobileAvatarMenu drawerOpen={accountDrawerOpen} setDrawerOpen={setAccountDrawerOpen} />
        </div>
      </MobileHeader>
    </div>
  );
};

export const AppSidebar = () => {
  const location = useLocation();

  return (
    <SidebarContainer>
      <Sidebar>
        <LogoContainer>
          <Link to={'/'}>
            <img style={{ width: '200px' }} src={logo} alt="logo" aria-label="Logo" />
          </Link>
        </LogoContainer>

        {location?.pathname != ROUTE_MODEL.LOGIN.PATH && (
          <>
            <NavContainer>{<SideBarMenu />}</NavContainer>

            <AccountAndLogoutContainer>
              <AccountAndLogout />
            </AccountAndLogoutContainer>
          </>
        )}
      </Sidebar>
    </SidebarContainer>
  );
};

export default AppSidebar;
