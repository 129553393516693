export const MAKES = [
  'BB',
  'BMW',
  'BSHT',
  'BUELL',
  'BUICK',
  'CAN AM',
  'CHEV',
  'CONST',
  'CUSTOM',
  'DODGE',
  'FORD',
  'FORRI',
  'HARLEY',
  'HD',
  'HOND',
  'HONDA',
  'HUSS',
  'INDIAN',
  'JAYCO',
  'JEEP',
  'KAW',
  'KAWA',
  'KAWASA',
  'KAWASK',
  'KAWS',
  'KTM',
  'KYMCO',
  'NISSAN',
  'POLAR',
  'RAM',
  'SUBRAR',
  'SUZI',
  'SUZUKI',
  'TOYOTA',
  'TRAILE',
  'TRIMPH',
  'TRIUMP',
  'ULTRA',
  'URAL',
  'VENTO',
  'VESPA',
  'VICTOR',
  'YAMA',
  'YAMAHA',
];
