import styled from 'styled-components';

import { COLORS, darkTheme } from 'constants/styles';

export const StyledMainContentContainer = styled.div`
  ${({ mobileView }) => (mobileView ? 'width: 100%' : 'width: calc(100% - 264px)')};
  ${({ mobileView }) => (mobileView ? 'height: calc(100% - 70px)' : 'height: 100vh')};
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
  padding: 12px 24px 12px 12px;
  position: fixed;
  right: 0;
  bottom: 0;
  color: ${darkTheme.colors.text};
  display: flex;
  flex-direction: column;
`;

export const StyledFlexGrowContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
`;

export const StyledFlexRow = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  margin: ${({ margin }) => margin || '0'} !important;
  padding: ${({ padding }) => padding || '10px 0px'} !important;
  width: ${({ width }) => width || '100%'};
  box-sizing: border-box;
  ${({ borderRight }) => borderRight && `border-right: 1px solid ${COLORS.borderColor}`};
  ${({ borderedTop }) => borderedTop && `border-top: 1px solid ${COLORS.borderColor}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: 1px solid ${COLORS.borderColor}`};
  ${({ outlined }) => outlined && `border: 1px solid ${COLORS.borderColor}`};
  ${({ background }) => background && `background: ${COLORS.gradientPrimary}`};
  ${({ backgroundColor }) => backgroundColor && `background: ${backgroundColor}`};
  ${({ fullHeight }) => fullHeight && `height: 100%`};
  ${({ height }) => height && `height: ${height}`};
  ${({ flexColumn }) => flexColumn && `flex-direction: column`};
  ${({ flexWrap }) => flexWrap && `flex-wrap: wrap`};
  ${({ overflowScroll }) => overflowScroll && `overflow-y: auto`};
  ${({ inline }) => inline && `display: inline-flex`};
  ${({ gap }) => gap && `gap: ${gap}`};
  ${({ pointer }) => pointer && `cursor: pointer`};
  ${({ rounded }) => rounded && `border-radius: 8px`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  ${({ borderColor }) => borderColor && `border-color: ${borderColor}`};
  ${({ onClick }) => onClick && `cursor: pointer`};
  ${({ height }) => height && `height: ${height}`};

  &:hover {
    ${({ hoverEffect }) =>
      hoverEffect &&
      `
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: rgba(0, 0, 0, 0.05);
  `}
  }
`;

export const StyledUnderNavBarContainer = styled.div`
  width: 100%;
  height: ${({ withProgress }) => (withProgress ? 'calc(100vh - 49px)' : 'calc(100vh - 45px)')};
  padding: ${({ padding }) => padding || '20px'};
  overflow-y: auto;
  box-sizing: border-box;
  background: ${COLORS.backgroundColor};
`;

export const StyledCreditReportContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - 45px);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCard = styled.div`
  background: ${darkTheme.colors.surface};
  padding: ${({ padding }) => padding || '20px'};
  ${({ fullwidth }) => fullwidth && `width: 100%`};
  ${({ fullHeight }) => fullHeight && `height: 100%`};
  ${({ maxWidth }) => `max-width: ${maxWidth || '1080px'}`};
  border-radius: 8px;
  box-sizing: border-box;
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ relative }) => relative && 'position: relative'};
  ${({ onClick }) => onClick && `cursor: pointer`};
`;

export const StyledTableContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: ${COLORS.surfaceColor};
`;

export const StyledAppLayout = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`;

export const StyledSidebar = styled.div`
  height: 100%;
  background: ${COLORS.surfaceColor};
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border-right: 1px solid ${COLORS.borderColor};
  z-index: 99;
`;

export const StyledToolbarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => padding || '0px 10px'};
  box-sizing: border-box;
`;

export const StyledUtilitySideBarItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #000;

  ${({ complete }) => complete && `color: ${COLORS.primary}`};

  ${({ error }) => error && `color: ${COLORS.error}`};

  ${({ disabled }) =>
    disabled &&
    `
    color: ${COLORS.disabledColor};
    cursor: default;
  `}

  ${({ active }) =>
    active &&
    `
    color: ${COLORS.primary};
    background: ${COLORS.primaryFaded};
    border: 1px solid ${COLORS.primary};
    border-left: 5px solid ${COLORS.primary}; 
  `}
  
  &:hover {
    ${({ disabled, active }) =>
      !disabled &&
      !active &&
      `
      color: ${COLORS.primary};
      background: ${COLORS.primaryFaded};   
  `}
  }
`;

export const StyledDataCard = styled.div`
  background: ${COLORS.backgroundColor};
  border-radius: 5px;
  padding: ${({ padding }) => padding || '10px 5px 0px'};
  width: calc(33% - 6px);
  box-sizing: border-box;
`;

export const StyledListItem = styled.div`
  width: 100%;
`;

export const StyledFileContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  border: 2px dashed ${COLORS.borderColor};
  background: ${COLORS.backgroundColor};
  padding: 5px;
  border-radius: 8px;

  ${({ variant }) =>
    variant === 'permanent' &&
    `
      border: 1px solid ${COLORS.borderColor};
    `}
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid ${COLORS.borderColor};
`;

export const StyledDataContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 158px);
  overflow-y: auto;
`;

export const StyledTableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  box-sizing: border-box;
  width: 100%;
`;

export const StyledCellContainer = styled.div`
  display: flex;
  ${({ justifyContent }) => `justify-content: ${justifyContent || 'flex-start'}`};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  box-sizing: border-box;
  padding: 0px 5px;
  margin: 0px;
  ${({ flexColumn }) => flexColumn && `flex-direction: column`};
`;
