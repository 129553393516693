import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { COLORS } from 'constants/styles';
import {
  StyledMainContentContainer,
  StyledFlexGrowContainer,
  StyledFlexRow,
  StyledUnderNavBarContainer,
  StyledCard,
  StyledTableContainer,
  StyledAppLayout,
  StyledSidebar,
  StyledToolbarContainer,
  StyledUtilitySideBarItem,
  StyledDataCard,
  StyledFileContainer,
  StyledCreditReportContentContainer,
} from './styled-components';

export const MainContentContainer = ({ children, ...rest }) => <StyledMainContentContainer {...rest}>{children}</StyledMainContentContainer>;

export const FlexGrowContainer = ({ children, ...rest }) => <StyledFlexGrowContainer {...rest}>{children}</StyledFlexGrowContainer>;

export const FlexRow = ({ children, ...rest }) => <StyledFlexRow {...rest}>{children}</StyledFlexRow>;

export const FileContainer = ({ children, ...rest }) => <StyledFileContainer {...rest}>{children}</StyledFileContainer>;

export const UnderNavBarContainer = ({ children, ...rest }) => <StyledUnderNavBarContainer {...rest}>{children}</StyledUnderNavBarContainer>;

export const CreditReportContentContainer = ({ children, ...rest }) => (
  <StyledCreditReportContentContainer {...rest}>{children}</StyledCreditReportContentContainer>
);

export const Card = ({ children, ...rest }) => <StyledCard {...rest}>{children}</StyledCard>;

export const TableContainer = ({ children, ...rest }) => <StyledTableContainer {...rest}>{children}</StyledTableContainer>;

export const AppLayout = ({ children, ...rest }) => <StyledAppLayout {...rest}>{children}</StyledAppLayout>;

export const Sidebar = ({ children, ...rest }) => <StyledSidebar {...rest}>{children}</StyledSidebar>;

export const ToolbarContainer = ({ children, ...rest }) => <StyledToolbarContainer {...rest}>{children}</StyledToolbarContainer>;

export const UtilitySideBarItem = ({ children, icon, label, disabled, onClick, ...rest }) => (
  <StyledUtilitySideBarItem disabled={disabled} onClick={() => !disabled && onClick()} {...rest}>
    {icon}
    <Typography sx={{ margin: '0px 0px 0px 10px' }} variant="body1">
      {label}
    </Typography>
  </StyledUtilitySideBarItem>
);

export const UtilitySidebar = ({ children, ...rest }) => (
  <FlexRow width="300px" fullHeight flexColumn alignItems="center" padding="20px 10px" {...rest}>
    {children}
  </FlexRow>
);

export const DataCard = ({ label, value, color, ...rest }) => (
  <StyledDataCard {...rest}>
    <Typography style={{ color: color ? color : COLORS.primary }} align="center" display="block" variant="h5">
      {value}
    </Typography>
    <Typography align="center" display="block" variant="overline">
      {label}
    </Typography>
  </StyledDataCard>
);

export const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.surfaceColor,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    padding: '10px',
    borderRadius: '5px',
    fontWeight: 400,
    boxShadow: theme.shadows[1],
  },
}));

export const ListItem = ({ primaryText, secondaryText, stackArray, children, icon, action, onClick }) => (
  <Fragment>
    <FlexRow onClick={onClick || null} padding="5px 10px 10px" margin="0" hoverEffect flexColumn>
      <FlexRow padding="0" margin="0" alignItems="center">
        {icon}

        <FlexRow padding="0" margin="0px 0px 0px 10px" flexColumn>
          <Typography variant="h6">{primaryText}</Typography>
          <Typography sx={{ marginTop: '-5px' }} variant="body2">
            {secondaryText}
          </Typography>
        </FlexRow>

        {action}
      </FlexRow>

      {stackArray && stackArray.length > 0 && (
        <Stack direction="row" style={{ marginTop: '10px' }} spacing={1} alignItems="flex-start">
          {stackArray
            .filter((chip) => chip.display)
            .map(({ label, icon, color, tip }, i) =>
              tip ? (
                <CustomTooltip key={`${label}-${i}`} arrow title={tip}>
                  <Chip color={color} label={label} icon={icon} />
                </CustomTooltip>
              ) : (
                <Chip key={`${label}-${i}`} color={color} label={label} icon={icon} />
              ),
            )}
        </Stack>
      )}

      {children}
    </FlexRow>

    <Divider />
  </Fragment>
);

export const BasicMenu = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
