import { METHODS, request } from 'services/axios';

const URIS = {
  GET_VEHICLES: (queryString) => (queryString ? `/vehicles${queryString}` : '/vehicles'),
  GET_VEHICLE_DETAIL: (identifier) => `/vehicles/${identifier}`,
};

export default {
  getVehicles: (queryString) =>
    request({
      url: URIS.GET_VEHICLES(queryString),
      method: METHODS.GET,
    }),

  getVehicleDetail: (identifier) =>
    request({
      url: URIS.GET_VEHICLE_DETAIL(identifier),
      method: METHODS.GET,
    }),
};
