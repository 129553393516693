import { METHODS, request } from 'services/axios';

const URIS = {
  LOGIN: () => `/auth/login`,
  LOGOUT: () => `/auth/logout`,
  GET_SESSION: () => `/auth/session`,
};

export default {
  login: ({ email, password }) =>
    request({
      url: URIS.LOGIN(),
      method: METHODS.POST,
      data: { username: email, password },
    }),

  logout: () =>
    request({
      url: URIS.LOGOUT(),
      method: METHODS.POST,
    }),

  getSession: () =>
    request({
      url: URIS.GET_SESSION(),
      method: METHODS.GET,
    }),
};
