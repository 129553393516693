import { useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2.js';

import { currencyFormatterNoDecimal, numberFormatterWithDecimals, numberFormatterNoCommas, percentageFormatter } from 'constants/formatters';
import { MinMaxAvgChart } from 'components/Charts/BarChart';
import { BooleanPieChartCard } from 'components/Charts/BooleanPieChart';

// TODO: move these and rename
import { calculateStatsAndReduceCategories, calculateSalesmanStats } from 'views/VehicleDeals/helpers';

const Statistics = ({ statistics }) => {
  const countByCity = useMemo(
    () => calculateStatsAndReduceCategories(statistics.customer_data?.count_by_city || {}),
    [JSON.stringify(statistics.customer_data?.count_by_city)],
  );
  const countByStates = useMemo(
    () => calculateStatsAndReduceCategories(statistics.customer_data?.count_by_state || {}),
    [JSON.stringify(statistics.customer_data?.count_by_state)],
  );
  const countByZip = useMemo(
    () => calculateStatsAndReduceCategories(statistics.customer_data?.count_by_zip_code || {}),
    [JSON.stringify(statistics.customer_data?.count_by_zip_code)],
  );
  const countByModel = useMemo(
    () => calculateStatsAndReduceCategories(statistics.vehicle_data?.count_by_model || {}),
    [JSON.stringify(statistics.vehicle_data?.count_by_model)],
  );
  const serviceWriterStats = useMemo(
    () => calculateSalesmanStats(statistics.employee_data?.count_by_service_writer || {}),
    [JSON.stringify(statistics.salesperson_data?.count_by_salesperson)],
  );
  const technicianStats = useMemo(
    () => calculateSalesmanStats(statistics.employee_data?.count_by_technician || {}),
    [JSON.stringify(statistics.salesperson_data?.count_by_technician)],
  );
  return (
    <Grid container spacing={2} style={{ overflowY: 'auto', paddingTop: '10px', marginTop: '0px' }}>
      <Grid xs={6}>
        <BooleanPieChartCard
          title="Requested By"
          dataset={[
            { name: 'Customer', value: statistics?.metadata?.request_by_customer },
            { name: 'Sales', value: statistics?.metadata?.request_by_sales },
          ]}
        />
      </Grid>
      <Grid xs={6}>
        <BooleanPieChartCard
          title="RO Type"
          dataset={[
            { name: 'Customer Job', value: statistics?.metadata?.customer_work },
            { name: 'Internal Job', value: statistics?.metadata?.internal_work },
          ]}
        />
      </Grid>

      {
        // title, stats, overrideFormatters, transformType, formatter
        [
          { transformType: 'valueSort', title: 'Cities', stats: countByCity, formatter: numberFormatterWithDecimals },
          { transformType: 'valueSort', title: 'States', stats: countByStates, formatter: numberFormatterWithDecimals },
          { transformType: 'valueSort', title: 'Zip Codes', stats: countByZip, formatter: numberFormatterWithDecimals },
          { transformType: 'valueSort', title: 'Models', stats: countByModel, formatter: numberFormatterWithDecimals },
          { formatter: numberFormatterNoCommas, title: 'Model Year', stats: statistics.vehicle_data?.year, transformType: 'keySort' },
          {
            transformType: 'valueSort',
            title: 'Service Writer',
            stats: { ...serviceWriterStats, count_by_bucket: statistics.employee_data?.count_by_service_writer },
            overrideFormatters: ['min', 'max'],
            formatter: numberFormatterWithDecimals,
          },
          {
            transformType: 'valueSort',
            title: 'Technician',
            stats: { ...technicianStats, count_by_bucket: statistics.employee_data?.count_by_technician },
            overrideFormatters: ['min', 'max'],
            formatter: numberFormatterWithDecimals,
          },
          {
            title: 'Actual Labor Hours',
            stats: statistics.hourly_data?.actual_labor_hours,
            transformType: 'keySortNumberic',
            formatter: numberFormatterWithDecimals,
          },
          {
            title: 'Billed Hours',
            stats: statistics.hourly_data?.billed_labor_hours,
            transformType: 'keySortNumberic',
            formatter: numberFormatterWithDecimals,
          },
          {
            title: 'Paid Tech Hours',
            stats: statistics.hourly_data?.pay_technician_hours,
            transformType: 'keySortNumberic',
            formatter: numberFormatterWithDecimals,
          },
          {
            title: 'Effective Labor Rate',
            stats: statistics.hourly_data?.effective_labor_rate_amount,
            transformType: 'keySortNumberic',
            formatter: currencyFormatterNoDecimal,
          },
          {
            title: 'Posted Labor Rate',
            stats: statistics.hourly_data?.labor_rate_amount,
            transformType: 'keySortNumberic',
            formatter: currencyFormatterNoDecimal,
          },
          { title: 'Efficiency', stats: statistics.hourly_data?.efficiency, transformType: 'keySortNumberic', formatter: percentageFormatter },
          {
            title: 'Days to Complete',
            stats: statistics.timing_data?.days_to_complete,
            transformType: 'keySortNumberic',
            formatter: numberFormatterWithDecimals,
          },
          { title: 'Labor Revenue', stats: statistics.financial_data?.labor?.revenue, transformType: 'keySortNumberic', formatter: currencyFormatterNoDecimal },
          { title: 'Labor Cost', stats: statistics.financial_data?.labor?.cost, transformType: 'keySortNumberic', formatter: currencyFormatterNoDecimal },
          {
            title: 'Labor Gross Profit',
            stats: statistics.financial_data?.labor?.gross?.profit,
            transformType: 'keySortNumberic',
            formatter: currencyFormatterNoDecimal,
          },
          {
            title: 'Labor Gross Margin',
            stats: statistics.financial_data?.labor?.gross?.margin,
            transformType: 'keySortNumberic',
            formatter: percentageFormatter,
          },
          { title: 'Parts Revenue', stats: statistics.financial_data?.parts?.revenue, transformType: 'keySortNumberic', formatter: currencyFormatterNoDecimal },
          { title: 'Parts Cost', stats: statistics.financial_data?.parts?.cost, transformType: 'keySortNumberic', formatter: currencyFormatterNoDecimal },
          {
            title: 'Parts Discount Amount',
            stats: statistics.financial_data?.parts?.discount?.amount,
            transformType: 'keySortNumberic',
            formatter: currencyFormatterNoDecimal,
          },
          {
            title: 'Parts Discount Percent',
            stats: statistics.financial_data?.parts?.discount?.percent,
            transformType: 'keySortNumberic',
            formatter: percentageFormatter,
          },
          {
            title: 'Parts Gross Profit',
            stats: statistics.financial_data?.parts?.gross?.profit,
            transformType: 'keySortNumberic',
            formatter: currencyFormatterNoDecimal,
          },
          {
            title: 'Parts Gross Margin',
            stats: statistics.financial_data?.parts?.gross?.margin,
            transformType: 'keySortNumberic',
            formatter: percentageFormatter,
          },
          { title: 'Total Revenue', stats: statistics.financial_data?.total?.revenue, transformType: 'keySortNumberic', formatter: currencyFormatterNoDecimal },
          { title: 'Total Cost', stats: statistics.financial_data?.total?.cost, transformType: 'keySortNumberic', formatter: currencyFormatterNoDecimal },
          {
            title: 'Total Gross Profit',
            stats: statistics.financial_data?.total?.gross?.profit,
            transformType: 'keySortNumberic',
            formatter: currencyFormatterNoDecimal,
          },
          {
            title: 'Total Gross Margin',
            stats: statistics.financial_data?.total?.gross?.margin,
            transformType: 'keySortNumberic',
            formatter: percentageFormatter,
          },
          {
            title: 'Shop Supplies',
            stats: statistics.financial_data?.total?.shop_supplies_amount,
            transformType: 'keySortNumberic',
            formatter: currencyFormatterNoDecimal,
          },
        ].map((stat) => (
          <Grid xs={6} key={stat.title}>
            <MinMaxAvgChart {...stat} />
          </Grid>
        ))
      }
    </Grid>
  );
};

export default Statistics;
