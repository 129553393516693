import { DataGrid } from '@mui/x-data-grid';

import { darkTheme } from 'constants/styles';
import { columns } from './helpers.js';
import { FlexGrowContainer } from 'components/Layouts/index.js';

const DealsDataGrid = ({ deals = [], updatePagination, metadata, limit = 25, page = 0 }) => {
  return (
    <FlexGrowContainer style={{ background: darkTheme.colors.surface, borderRadius: '8px', marginTop: '10px' }}>
      <DataGrid
        // onRowClick={(loan) => navigate(LINKS.LENDER.LOANS.VIEW_ONE(loan?.id, 'summary'))} // TODO: onClick, show details of the deal.
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        disableColumnMenu
        columns={columns}
        rows={deals}
        paginationModel={{
          pageSize: Math.min(Number(limit), 100),
          page: Number(page),
        }}
        onPaginationModelChange={(params) => updatePagination({ page: params.page, limit: params.pageSize })}
        paginationMode="server"
        rowCount={metadata?.total ? Number(metadata.total) : 0}
      />
    </FlexGrowContainer>
  );
};

export default DealsDataGrid;
