import { METHODS, request } from 'services/axios';

const URIS = {
  GET_DEALS: (queryString) => (queryString ? `/vehicle-deals${queryString}` : '/vehicle-deals'),
  GET_DEAL_DETAIL: (identifier) => `/vehicle-deals/${identifier}`,
};

export default {
  getDeals: (queryString) =>
    request({
      url: URIS.GET_DEALS(queryString),
      method: METHODS.GET,
    }),

  getDealDetail: (identifier) =>
    request({
      url: URIS.GET_DEAL_DETAIL(identifier),
      method: METHODS.GET,
    }),
};
