import { formatCurrencyForDataGrid, formatDateForDataGrid, percentageFormatterV2 } from 'constants/formatters';

export const columns = [
  {
    field: 'work_order_id',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Work Order #',
    flex: 1,
    minWidth: 120,
  },
  {
    field: 'type',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Type',
    flex: 1,
    minWidth: 110,
    valueGetter: (params) => params.row.type.toUpperCase(),
  },
  {
    field: 'customer_name',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Customer Name',
    flex: 1,
    minWidth: 220,
  },
  {
    field: 'vehicle_vin',
    headerAlign: 'left',
    align: 'left',
    headerName: 'VIN',
    flex: 1,
    minWidth: 180,
  },
  {
    field: 'vehicle_stock_number',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Stock #',
    flex: 1,
    minWidth: 110,
  },
  {
    field: 'vehicle_year',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Year',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'vehicle_model',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Model',
    flex: 1,
    minWidth: 120,
  },
  {
    field: 'billed_labor_hours',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Billed Hours',
    flex: 1,
    minWidth: 120,
    type: 'number',
  },
  {
    field: 'actual_labor_hours',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Worked Hours',
    flex: 1,
    minWidth: 130,
    type: 'number',
  },
  {
    field: 'pay_technician_hours',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Tech Pay Hours',
    flex: 1,
    minWidth: 130,
    type: 'number',
  },
  {
    field: 'efficiency',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Efficiency',
    flex: 1,
    minWidth: 100,
    type: 'number',
    valueFormatter: ({ value }) => percentageFormatterV2(value, 0),
  },
  {
    field: 'total_revenue_amount',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Revenue',
    flex: 1,
    minWidth: 100,
    type: 'number',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'total_cost',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Cost',
    flex: 1,
    minWidth: 100,
    type: 'number',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'total_gross_profit',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Profit',
    flex: 1,
    minWidth: 100,
    type: 'number',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'total_gross_margin',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Margin',
    flex: 1,
    minWidth: 100,
    type: 'number',
    valueFormatter: ({ value }) => percentageFormatterV2(value, 0),
  },
  {
    field: 'labor_revenue_amount',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Labor Revenue',
    flex: 1,
    minWidth: 130,
    type: 'number',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'labor_cost',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Labor Cost',
    flex: 1,
    minWidth: 100,
    type: 'number',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'labor_gross_profit',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Labor Profit',
    flex: 1,
    minWidth: 120,
    type: 'number',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'labor_gross_margin',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Labor Margin',
    flex: 1,
    minWidth: 120,
    type: 'number',
    valueFormatter: ({ value }) => percentageFormatterV2(value, 0),
  },
  {
    field: 'parts_revenue_amount',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Parts Revenue',
    flex: 1,
    minWidth: 130,
    type: 'number',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'total_inventory_items_cost',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Parts Cost',
    flex: 1,
    minWidth: 100,
    type: 'number',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'parts_gross_profit',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Parts Profit',
    flex: 1,
    minWidth: 100,
    type: 'number',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'parts_gross_margin',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Parts Margin',
    flex: 1,
    minWidth: 120,
    type: 'number',
    valueFormatter: ({ value }) => percentageFormatterV2(value, 0),
  },
  {
    field: 'arrival_date',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Arrival Date',
    flex: 1,
    minWidth: 140,
    valueFormatter: ({ value }) => formatDateForDataGrid(value),
    type: 'date',
  },
  {
    field: 'completed_date',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Completed Date',
    flex: 1,
    minWidth: 140,
    valueFormatter: ({ value }) => formatDateForDataGrid(value),
    type: 'date',
  },
  {
    field: 'days_to_complete',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Days to Complete',
    flex: 1,
    minWidth: 150,
    type: 'number',
  },
  {
    field: 'service_writer_employee_code',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Service Writer',
    flex: 1,
    minWidth: 120,
  },
  {
    field: 'technician_time_entires',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Technicians',
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => {
      return params.row?.technician_time_entries?.map((entry) => `${entry.technician}: ${percentageFormatterV2(entry.percentage, 0)}`).join(' || ');
    },
  },
];
